// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: "https://api.financierapoint.com.mx",
  encryptKey: '@6kTGB!XrpQEjOkwBY!CBLIR$h3aR0wu',
  secretKeyEncrypt: 'U2FsdGVkX192vHck5EgW8LZ/LsxnGyQrT+/dmf1+G4vONGPd3sXZaPlwd5hVAVpu5Z87n4G6z0bHEY+DosiSNAkPau4EWBG/93LmgROGY2yNc3AdmLE8gRH6R1Hslkz5',
  userAuth: 'Point-Web',
  googleMapsApiKey: "AIzaSyBDoTCNRZ1O1VSm9cSARr0mxPpEC9a-Jbk",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
